function formatJson(filterVal, jsonData) {
  // console.info(jsonData);
  return jsonData.map((v) => filterVal.map((j) => v[j]));
}
export function export2Excel(tHeader, filterVal, temp, fileName) {
  require.ensure([], () => {
    const { export_json_to_excel } = require("./Export2Excel.js"); //刚刚新建Export2Excel.js文件的路径
    const list = temp; //table数据
    const data = formatJson(filterVal, list);
    const realName = fileName ? fileName : new Date();
    export_json_to_excel(tHeader, data, realName); //导出文件名称
  });
}
export function exportSheets2Excel(sheets, fileName) {
  require.ensure([], () => {
    const { export_sheet_json_to_excel } = require("./Export2Excel");
    const data = sheets.map((sheet) => {
      return {
        jsonData: formatJson(sheet.filterVal, sheet.list),
        th: sheet.tHeader,
        sheetName: sheet.sheetName
      };
    });
    export_sheet_json_to_excel(data, fileName);
  });
}
