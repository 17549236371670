<template>
  <div class="page-title">
    <div class="block-div"></div>
    <div class="title-box" v-if="ifEdit">
      <div class="input-box">
        <el-input
          size="small"
          v-model.trim="newName"
          @keyup.enter="handleClickSave()"
        ></el-input>
      </div>
      <el-icon
        v-show="!ifShowLoading"
        class="icon-check"
        @click="handleClickSave()"
      >
        <Check />
      </el-icon>
      <el-icon
        v-show="!ifShowLoading"
        class="icon-close"
        @click="handleClickCancelRename()"
      >
        <Close />
      </el-icon>
      <el-icon class="icon-loading" v-show="ifShowLoading"><Loading /></el-icon>
    </div>
    <div class="title-box" v-else>
      <span :style="{ color: fontColor }">{{ title }}</span>
      <i
        v-if="editable"
        class="iconfont icon-bianji edit-icon"
        @click.stop="showEditor()"
      ></i>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, nextTick, defineEmits } from "vue";

const props = defineProps({
  title: {
    type: String
  },
  // 负责控制是否显示编辑标签
  editable: {
    type: Boolean,
    default: () => false
  },
  // 负责控制是否显示输入框
  edit: {
    type: Boolean,
    default: () => false
  },
  loading: {
    type: Boolean,
    default: () => false
  },
  fontColor: {
    type: String,
    default: () => ""
  }
});
const emits = defineEmits([
  "update:title",
  "update:loading",
  "save",
  "update:edit",
  "cancel"
  // "go"
]);

const newName = ref("");

const ifShowLoading = ref(false);
watch(
  () => props.loading,
  (newVal) => {
    ifShowLoading.value = newVal;
    // nextTick(() => {
    //   hideLoading();
    // });
    // if (ifEdit.value) {
    //   nextTick(() => {
    //     hideLoading();
    //     showEditor();
    //   });
    // }
  },
  {
    immediate: true
  }
);
const showLoading = () => {
  ifShowLoading.value = true;
  emits("update:loading", ifShowLoading.value);
};
const hideLoading = () => {
  ifShowLoading.value = false;
  emits("update:loading", ifShowLoading.value);
};
let ifEdit = ref(false);

watch(
  () => props.edit,
  (newVal) => {
    ifEdit.value = newVal;
    nextTick(() => {
      hideLoading();
    });
    if (ifEdit.value) {
      nextTick(() => {
        hideLoading();
        showEditor();
      });
    }
  },
  {
    immediate: true
  }
);
const showEditor = () => {
  ifEdit.value = true;
  emits("update:edit", ifEdit.value);

  newName.value = props.title;
};
const hideEditor = () => {
  ifEdit.value = false;
  emits("update:edit", ifEdit.value);
};
const handleClickSave = () => {
  showLoading();
  // ifEdit.value = false;
  // emits("update:edit", ifEdit.value);
  if (props.ifBind) {
    emits("update:title", newName.value);
  }
  emits("save", newName.value);
};

const handleClickCancelRename = () => {
  hideEditor();
};
</script>
<style scoped lang="scss">
.page-title {
  display: inline-flex;
  line-height: 30px;
  .block-div {
    $block-div-width: 6px;
    width: $block-div-width;
    height: 30px;
    background-color: red;
    margin-left: -20px;
    margin-right: $main-page-wrapper-padding - $block-div-width;
  }
  .title-box {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    .edit-icon {
      margin-left: 8px;
      @include pointer();
    }
    .input-box {
      width: 50%;
      display: inline-block;
    }
    .icon {
      margin-left: 8px;
      font-size: 18px;
      vertical-align: middle;
      @include pointer();
    }

    .icon-check {
      @extend .icon;
      color: $success-color;
    }

    .icon-close {
      @extend .icon;
      color: $primary-color;
    }
    .icon-loading {
      @extend .icon;
    }
  }
}
</style>
